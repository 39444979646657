/*
  Profile header component, contains avatar, name,
  bio, and username. If owner, displays edit button
  that opens edit profile modal
*/

import { useAuthUser } from 'next-firebase-auth'
import { ReactElement } from 'react'

import { Button, HStack, Text, VStack, useMediaQuery } from '@chakra-ui/react'

import { useSettingsModalActions } from '../../context/settings-modal/context'
import { User } from '../../context/user/types'
import DefaultAvatar from '../general/DefaultAvatar'

interface Props {
  user: User
}

const ProfileHeader = ({ user }: Props): ReactElement => {
  const authUser = useAuthUser()
  const { openSettings } = useSettingsModalActions()
  const userId = authUser?.firebaseUser?.uid

  const isOwner = userId != undefined && user?.uid === userId

  const [isMobile] = useMediaQuery('(max-width: 840px)')
  return (
    <HStack w="100%">
      <DefaultAvatar size={isMobile ? 'xl' : 'xl'} user={user} />

      <DisplayInfo user={user} />

      {isOwner && (
        <Button
          verticalAlign="top"
          onClick={() => openSettings('profile')}
          px={4}
          bg="white"
          boxShadow="0 0 4px rgb(0 0 0 / 0.35)"
        >
          Edit
        </Button>
      )}
    </HStack>
  )
}

interface DisplayInfoProps {
  user: User
}
const DisplayInfo = ({ user }: DisplayInfoProps): ReactElement => {
  const [isMobile] = useMediaQuery('(max-width: 840px)')

  return (
    <VStack w="100%" paddingLeft="6" align="start" spacing={2}>
      {/* <VStack w="100%" align="start" spacing={0}> */}
      <Text fontSize={isMobile ? 'xl' : '3xl'} fontWeight="500">
        {user.displayName}
      </Text>

      {/* <Text fontSize={isMobile ? 'lg' : 'xl'} color="gray.750">
          @{user.username}
        </Text> */}
      {/* </VStack> */}

      <Text fontSize="lg">{user.bio}</Text>
    </VStack>
  )
}

export default ProfileHeader
