/*
Hook to fetch 1 user that matches the given username
*/

import { useEffect, useState } from 'react'
import db from '../firebase/firestore'
import { User } from '../../context/user/types'

interface UserReturnType {
  isLoading: boolean
  user: User | undefined
}

export const useUserForUsername = (
  username: string | string[] | undefined
): UserReturnType => {
  const [user, setUser] = useState<User | undefined>()
  const [isLoading, setIsLoading] = useState(true)
  let usernameString: string | undefined
  if (typeof username === 'string' || username == undefined)
    usernameString = username
  else if (username.length === 1) usernameString = username[0]
  else usernameString = undefined

  useEffect(() => {
    if (usernameString == undefined) return
    const unsubscribe = db
      .collection('users')
      .where('username', '==', usernameString)
      .limit(1)
      .onSnapshot((docSnapshot) => {
        if (docSnapshot.docs.length > 0) {
          const user = docSnapshot.docs[0].data() as User
          setUser(user)
        }
        setTimeout(() => setIsLoading(false), 200)
      })
    return unsubscribe
  }, [usernameString])

  return { isLoading, user }
}

export default useUserForUsername
