import { ReactElement } from 'react'
import { Grid, GridItem } from '@chakra-ui/react'
import { Dashboard } from '../dashboard/dashboardDisplay/DashboardDisplayV3'
import DashboardCard from './DashboardCard'

interface Props {
  dashboards: Dashboard[]
  type?: 'profile' | 'home'
}

const DashboardTiles = ({ dashboards, type = 'home' }: Props): ReactElement => {
  return (
    <Grid gap={6} templateColumns="repeat(12, 1fr)" w={'100%'}>
      {dashboards.map((dashboard) => {
        const created = new Date(dashboard.created.seconds * 1000)
        return (
          <GridItem
            key={dashboard.id}
            colSpan={type === 'profile' ? [12, 6, 6, 6, 4] : [12, 6, 6, 4, 4]}
          >
            <DashboardCard
              author={dashboard.author}
              created={created}
              description={dashboard.description}
              followerCount={dashboard.followerCount}
              id={dashboard.id}
              isPublic={dashboard.isPublic}
              tags={dashboard.tags}
              title={dashboard.title}
              widgetCounts={dashboard.widgetCounts}
            />
          </GridItem>
        )
      })}
    </Grid>
  )
}

export default DashboardTiles
