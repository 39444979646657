import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { ReactElement } from 'react'
import { BsPersonFill } from 'react-icons/bs'

import {
  Box,
  HStack,
  Spacer,
  Text,
  Tooltip,
  VStack,
  Wrap,
} from '@chakra-ui/react'

import { routes } from '../../../utils/constants'
import { DashboardAuthor } from '../../dashboard/dashboardDisplay/DashboardDisplayV3'
import DashboardTag from '../../dashboard/dashboardDisplay/header/DashboardTag'
import DefaultAvatar from '../../general/DefaultAvatar'
import { WidgetType } from '../../widgets/types'
import WidgetCountPill from './WidgetCountPill'

interface DashboardCardProps {
  author: DashboardAuthor
  created: Date
  description: string
  followerCount: number
  id: string
  isPublic: boolean
  hideAuthor?: boolean
  tags: string[]
  title: string
  widgetCounts: Record<WidgetType, number>
}

function DashboardCard({
  author,
  description,
  followerCount,
  id,
  hideAuthor = false,
  tags,
  title,
  widgetCounts,
}: DashboardCardProps): ReactElement {
  const router = useRouter()

  const dashboardWidgetCounts = (
    Object.keys(widgetCounts ?? {}) as Array<WidgetType>
  )
    .map((widgetKey) => ({ type: widgetKey, count: widgetCounts[widgetKey] }))
    .filter(({ count }) => count > 0)
    .sort((a, b) => a.type.localeCompare(b.type))
    .sort((a, b) => b.count - a.count)

  const url = routes.dashboard(id)

  const handleClickCard = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    router.push(url)
  }

  const handleClickUsername = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    if (author?.username != undefined) {
      router.push(routes.profile(author.username))
    }
  }

  const numWidgets = dashboardWidgetCounts
    .map(({ count }) => count)
    .reduce((count, val) => val + count, 0)

  return (
    // <LinkBox zIndex={50} h="min-content">
    <VStack
      align="start"
      borderRadius="md"
      py={3}
      px={5}
      w="100%"
      bg="white"
      boxShadow="0 0.75px 2.5px rgb(0 0 0 / 0.5)"
      _hover={{
        bg: 'gray.50',
      }}
      h="max-content"
      spacing={1}
      as="a"
      href={url}
      onClick={handleClickCard}
    >
      <HStack w="100%" spacing={4} align={'flex-start'} maxW="100%">
        <NextLink href={url}>
          <Text fontSize="xl" fontWeight="600" overflow="hidden" isTruncated>
            {title}
          </Text>
        </NextLink>
        <Spacer />
        <Tooltip
          label="Followers"
          m={1}
          hasArrow
          placement="bottom"
          openDelay={250}
        >
          <HStack spacing={1}>
            <BsPersonFill color="black" />
            <Text color="black">{followerCount}</Text>
          </HStack>
        </Tooltip>
      </HStack>
      {!hideAuthor && author != undefined && (
        <Text
          textAlign="center"
          color="brand.username"
          as="a"
          href={routes.profile(author.username ?? '')}
          onClick={handleClickUsername}
          _hover={{ cursor: 'pointer', textDecoration: 'underline' }}
        >
          <Box as="span" mr={1.5}>
            <DefaultAvatar size="xs" user={author} />
          </Box>
          {author.displayName}
        </Text>
      )}

      <Text size="sm" align="start">
        {description}
      </Text>

      {(tags ?? []).length > 0 && (
        <Wrap pt={1}>
          {tags.slice(0, 5).map((tag) => (
            <DashboardTag tag={tag} key={tag} />
          ))}
        </Wrap>
      )}
      <HStack pt={2} pb={-1} align="end" maxW="100%">
        <Wrap pt={2}>
          {dashboardWidgetCounts.map(({ type, count }) => (
            <WidgetCountPill key={type} type={type} count={count} />
          ))}
          {numWidgets === 0 && (
            <Text fontSize={14} color="gray.400" fontStyle={'oblique'}>
              No widgets
            </Text>
          )}
        </Wrap>
        <Spacer />
      </HStack>
    </VStack>
    // </LinkBox>
  )
}

export default DashboardCard
