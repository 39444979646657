/*
User profile page that takes username as url param
*/

import { withAuthUser } from 'next-firebase-auth'

import React, { ReactElement } from 'react'

import PageLayout from '../../components/layout/PageLayout'
import ProfileDisplay from '../../components/profile/ProfileDisplay'
import useUserForUsername from '../../utils/auth/useUserForUsername'
import { useRouter } from 'next/router'
import { Center, Spinner } from '@chakra-ui/react'
import useDashboards from '../../context/dashboard-navigation/useDashboards'

function Profile(): ReactElement {
  const router = useRouter()
  const { username } = router.query

  const { isLoading, user } = useUserForUsername(username)
  const { followedDashboards, originalDashboards } = useDashboards(
    user ?? undefined
  )

  if (user == undefined && !isLoading) {
    router.replace('/404')
    return <></>
  }

  if (user == undefined) {
    return (
      <Center h="100vh" w="100%">
        <Spinner />
      </Center>
    )
  }

  // // Original dashboards
  // const originalDashboardsPromise = db
  //   .collection('dashboards')
  //   .where('author.id', '==', user.uid)
  //   .get()
  //   .then((snapshot) => {
  //     return snapshot.docs.map((doc) => {
  //       return doc.data() as Dashboard
  //     })
  //   })

  // // Followed dashboards
  // if (!user.hasOwnProperty('followedDashboards')) {
  //   user.followedDashboards = []
  // }

  // const followedDashboardPromises = user.followedDashboards.map(
  //   (dashboardId) => {
  //     return db
  //       .doc(`dashboards/${dashboardId}`)
  //       .get()
  //       .then((doc) => {
  //         if (!doc.exists) {
  //           return undefined
  //         }
  //         return doc.data() as Dashboard
  //       })
  //   }
  // )

  // const followedDashboardsPromise: Promise<(Dashboard | undefined)[]> =
  //   Promise.all(followedDashboardPromises)

  // // Sort dashboards by followerCount
  // function compare(a: Dashboard, b: Dashboard) {
  //   if (a.followerCount < b.followerCount) {
  //     return 1
  //   }
  //   if (a.followerCount > b.followerCount) {
  //     return -1
  //   }
  //   return 0
  // }

  return (
    <ProfileDisplay
      user={user}
      originalDashboards={originalDashboards}
      followedDashboards={followedDashboards}
    />
  )
}
const getLayout = (page: ReactElement): ReactElement => (
  <PageLayout>{page}</PageLayout>
)

Profile.getLayout = getLayout

export default withAuthUser()(Profile)
