/*
  Component that holds the contents of a user's
  profile page
*/

import { ReactElement } from 'react'

import { Center, Divider, Grid, GridItem, Text, VStack } from '@chakra-ui/react'

import { User } from '../../context/user/types'
import { Dashboard } from '../dashboard/dashboardDisplay/DashboardDisplayV3'
import DashboardTiles from './DashboardTiles'
import ProfileHeader from './ProfileHeader'

interface Props {
  user: User
  originalDashboards: Dashboard[] | undefined
  followedDashboards: Dashboard[] | undefined
}

function ProfileDisplay({
  user,
  originalDashboards,
  followedDashboards,
}: Props): ReactElement {
  return (
    <Center py={5} px={5} w="100%" h="100%" overflowY="scroll">
      <Grid gap={6} templateColumns="repeat(8, 1fr)" w="100%" h="100%">
        <GridItem colSpan={[8, 8, 8, 6, 6]} colStart={[1, 1, 1, 2, 2]}>
          <VStack spacing={6} w="100%">
            <ProfileHeader user={user} />
            <Divider />

            {originalDashboards != undefined && originalDashboards.length > 0 && (
              <VStack w="100%" align="flex-start" spacing={2} paddingTop="2">
                <Text fontSize="md" fontWeight="400">
                  Dashboards
                </Text>
                <DashboardTiles
                  dashboards={originalDashboards}
                  type="profile"
                />
              </VStack>
            )}

            {followedDashboards != undefined && followedDashboards.length > 0 && (
              <VStack w="100%" align="flex-start" spacing={2} paddingTop="4">
                <Text fontSize="md" fontWeight="400">
                  Following
                </Text>
                <DashboardTiles
                  dashboards={followedDashboards}
                  type="profile"
                />
              </VStack>
            )}
          </VStack>
        </GridItem>
      </Grid>
    </Center>
  )
}

export default ProfileDisplay
